import { TAX_CALCULATION } from "@/models/enums/tax.enum";
import {
  PurchaseOrderCreateDto,
  PurchaseOrderFormProductState,
  PurchaseOrderFormState,
  PurchaseOrderLineResponseDto,
  PurchaseOrderProductCreateDto,
  PurchaseOrderResponseDto,
  PurchaseOrderUpdateDto,
} from "@/models/interface/purchase-order";
import { LabelInValue } from "@/types";
import { StringUtils } from "@/utils";
import {
  changeCurrencytoNumeric,
  currencyFormat,
} from "@/validator/globalvalidator";
import { Decimal } from "decimal.js-light";
import moment from "moment";

export class PurchaseOrderMapper {
  static toCreateProductDto(
    state: PurchaseOrderFormProductState,
    taxType: string,
    isLuxuryGoods: boolean
  ): PurchaseOrderProductCreateDto {
    const isInclusiveNonLuxury =
      StringUtils.compare(taxType, TAX_CALCULATION.INCLUSIVE) && !isLuxuryGoods;
    const product: PurchaseOrderProductCreateDto = {
      assetId: state.assetId || null,
      lastPrice: state.lastPrice,
      productId: state.idProduct,
      productLocationId: state.locationNeeded,
      uomId: state.uom,
      merk: state.merk,
      qty: changeCurrencytoNumeric(state.qty),
      price: changeCurrencytoNumeric(state.price),
      taxValue: state.taxValueForCalculation.toString(),
      baseAmount: state.taxBaseForCalculation.toString(),
      subTotal: state.totalForCalculation.toString(),
      taxCode: state.taxRate,
      description: state.description,
      itemAssets: state.itemAssets.map(item => ({
        capacity: item.capacity,
        serialNumber: item.serialNumber,
        specification: item.specification,
        type: item.type,
      })),
      id: state.id || null,
      discountValue: changeCurrencytoNumeric(state.discountValue),
      inclusiveTaxRateId: isInclusiveNonLuxury
        ? state.inclusiveTax?.key ?? null
        : null,
    };
    return product;
  }

  static toCreateDto(state: PurchaseOrderFormState): PurchaseOrderCreateDto {
    const dto: PurchaseOrderCreateDto = {
      branchWarehouseId: state.branch?.key ?? "",
      currency: state.currency ?? "",
      currencyRate: changeCurrencytoNumeric(state.rate || 1),
      date: moment(state.date).format(),
      description: state.description ?? "",
      isLuxuryGoods: state.isLuxuryGoods,
      fileAttachment: state.attachment ? [state.attachment] : null,
      poRequisitionId: state.purchaseRequisitionId ?? null,
      poType: state.purchaseOrderType ?? "",
      purchaseOrderLines: state.products.map<PurchaseOrderProductCreateDto>(
        product =>
          PurchaseOrderMapper.toCreateProductDto(
            product,
            state.tax as string,
            state.isLuxuryGoods
          )
      ),
      refNumber: state.refNumber ?? null,
      requesterId: state.requester?.key ?? "",
      supplierBillToAddress: state.supplierBillAddress ?? "",
      supplierId: state.supplierName?.key ?? "",
      taxType: state.tax ?? "",
      arrivalEstimatedDate: state.arrivalEstimated?.format() ?? "",
      status: state.status ?? "Draft",
      assigneeId: state.assignee?.key ?? null,
    };

    return dto;
  }

  static toUpdateDto(state: PurchaseOrderFormState): PurchaseOrderUpdateDto {
    const createDto = PurchaseOrderMapper.toCreateDto(state);
    const dto: PurchaseOrderUpdateDto = {
      ...createDto,
      deletedPurchaseOrderLines:
        Array.isArray(state.deletedLineIds) && state.deletedLineIds.length > 0
          ? state.deletedLineIds
          : null,
    };
    return dto;
  }

  static toFormProductState(
    lineDto: PurchaseOrderLineResponseDto
  ): PurchaseOrderFormProductState {
    let inclusiveTax: LabelInValue | undefined = undefined;
    if (lineDto.inclusiveTaxRateId && lineDto.inclusiveTaxRateName) {
      inclusiveTax = {
        key: lineDto.inclusiveTaxRateId,
        label: lineDto.inclusiveTaxRateName,
      };
    }
    return {
      inclusiveTax,
      inclusiveTaxRate: lineDto.inclusiveTaxRate ?? 0,
      id: lineDto.id,
      key: 0,
      no: 0,
      productCode: lineDto.productCode,
      productName: lineDto.productName,
      uom: lineDto.uomId,
      merk: lineDto.merk,
      qty: currencyFormat(lineDto.qty),
      taxBase: currencyFormat(lineDto.baseAmount),
      discountValue: currencyFormat(lineDto.discountValue),
      assetId: lineDto.assetId,
      taxRate: lineDto.taxCodeId,
      taxValue: lineDto.taxValue,
      taxRatePercent: lineDto.taxRate,
      total: currencyFormat(lineDto.subTotal),
      lastPrice: lineDto.lastPrice,
      price: currencyFormat(lineDto.price),
      description: lineDto.description,
      locationNeeded: lineDto.productLocationId,
      idProduct: lineDto.productId,
      notes: lineDto.itemAssets[0]?.serialNumber,
      grossValue: currencyFormat(lineDto.grossValue ?? 0),
      itemAssets: lineDto.itemAssets.map((mappingAsset, indexMap) => {
        return { ...mappingAsset, key: indexMap };
      }),
      grossValueForCalculation: new Decimal(0),
      taxBaseForCalculation: new Decimal(0),
      taxValueForCalculation: new Decimal(0),
      totalForCalculation: new Decimal(0),
      optionCustom: [
        {
          name: "productCode",
          option: [
            {
              id: lineDto.productId,
              name: lineDto.productCode,
            },
          ],
        },
        {
          name: "productName",
          option: [
            {
              id: lineDto.productId,
              name: lineDto.productName,
            },
          ],
        },
        {
          name: "uom",
          option: [
            {
              id: lineDto.uomId,
              name: lineDto.uom,
            },
          ],
        },
      ],
      disabledInput: true,
      disabledSelect: true,
      disableByRow: [
        "productCode",
        "productName",
        "uom",
        "locationNeeded",
        "merk",
        "qty",
        "price",
        "taxBase",
        "taxRate",
        "total",
        "description",
      ],
    };
  }

  static toFormState(dto: PurchaseOrderResponseDto): PurchaseOrderFormState {
    let assignee: LabelInValue | undefined = undefined;
    if (dto.assigneeId && dto.assigneeName) {
      assignee = {
        key: dto.assigneeId,
        label: dto.assigneeName,
      };
    }

    return {
      assignee,
      purchaseOrderNumber: dto.documentNumber,
      purchaseRequisitionId: dto.purchaseRequisitionId ?? undefined,
      purchaseRequisitionNumber: dto.purchaseRequisitionNo ?? undefined,
      branch: {
        label: dto.branchWarehouseName,
        key: dto.branchWarehouseId,
      },
      requester: {
        label: dto.requesterName,
        key: dto.requesterId,
      },
      date: dto.date ? moment(dto.date) : null,
      supplierName: {
        label: dto.supplierName,
        key: dto.supplierId,
      },
      supplierBillAddress: dto.supplierBillToAddress,
      currency: dto.currency,
      tax: dto.taxType,
      refNumber: dto.refNumber,
      description: dto.description,
      purchaseOrderType: dto.poType,
      status: dto.status,
      arrivalEstimated: dto.arrivalEstimatedDate
        ? moment(dto.arrivalEstimatedDate)
        : undefined,
      total: currencyFormat(dto.total),
      totalTax: currencyFormat(dto.totalTax),
      grandTotal: currencyFormat(dto.grandTotal),
      totalDiscount: currencyFormat(dto.totalDiscount),
      rate: currencyFormat(dto.currencyRate),
      note: dto.closedNote,
      isLuxuryGoods: dto.isLuxuryGoods,
      attachment: undefined,
      totalQty: currencyFormat(dto.totalQty),
      products: [],
      deletedLineIds: [],
    };
  }
}
