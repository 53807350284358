









































































































































































































































































































































































































































import { SearchBuilder } from "@/builder";
import CharacterLength from "@/components/CharacterLength/CharacterLength.vue";
import { debounceProcess } from "@/helpers/debounce";
import {
  useAsset,
  useBranch,
  useCoa,
  useContactData,
  useCurrency,
  useInvoiceAPPrepayment,
  useLocalFilter,
  usePreferences,
  usePurchaseOrder,
} from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { AP_PREPAYMENT_STATUS } from "@/models/enums/ApPrepayment.enum";
import { Mode } from "@/models/enums/global.enum";
import { Messages } from "@/models/enums/messages.enum";
import { DataListMasterAsset } from "@/models/interface/assets.interface";
import { ContactData } from "@/models/interface/contact.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  CreateInvoiceApPrepayment,
  InvoiceApPrepaymentData,
} from "@/models/interface/InvoiceApPrepayment.interface";
import { DataWarehouseBranch } from "@/models/interface/logistic.interface";
import { DataResponseGetListPurchaseOrder } from "@/models/interface/purchase.interface";
import {
  DataMasterCurrency,
  ResponsePreference,
} from "@/models/interface/settings.interface";
import { FormMeta } from "@/store/ApPrepayment.store";
import {
  currencyFormat,
  formatTaxNumber,
  formatTaxNumbertoNumber,
  formatterNumber,
  reverseFormatNumber,
} from "@/validator/globalvalidator";
import { WrappedFormUtils } from "ant-design-vue/types/form/form";
import moment, { Moment } from "moment";
import { Component, Mixins } from "vue-property-decorator";
import { mapActions, mapMutations, mapState } from "vuex";

const ALLOW_TO_CANCEL: AP_PREPAYMENT_STATUS[] = ["Unpaid"];
const ALLOW_TO_REJECT_OR_APPROVE: AP_PREPAYMENT_STATUS[] = ["Need Approval"];

@Component<CreateInvoicesPrepaymentDebitMemo>({
  provide() {
    return {
      formTable: this.formTable,
    };
  },
  beforeRouteLeave(_to, _from, next) {
    this.resetStore();
    next();
  },
  components: { CharacterLength },
  computed: {
    ...mapState({
      storeBaseDecimalPlace: (st: any) =>
        st.preferenceStore.baseDecimalPlace as number,
      storeFormMeta: (st: any) => st.aPPrepaymentStore.formMeta,
      storeSubtotal: (st: any) => st.aPPrepaymentStore.subtotal,
      storeTotalTax: (st: any) => st.aPPrepaymentStore.totalTax,
      storeTotalPayment: (st: any) => st.aPPrepaymentStore.total,
      storeTaxRate: (st: any) => st.aPPrepaymentStore.taxRate,
      storeDetail: (st: any) => st.aPPrepaymentStore.detail,
      storePreference: (st: any) => st.preferenceStore.dataPreference,
    }),
  },
  methods: {
    ...mapMutations({
      setFormMeta: "aPPrepaymentStore/setFormMeta",
      setDetail: "aPPrepaymentStore/setDetail",
      setSubtotal: "aPPrepaymentStore/setSubtotal",
      setTaxRate: "aPPrepaymentStore/setTaxRate",
      setTotalTax: "aPPrepaymentStore/setTotalTax",
      setTotal: "aPPrepaymentStore/setTotal",
    }),
    ...mapActions({
      resetStore: "aPPrepaymentStore/resetStore",
    }),
  },
})
export default class CreateInvoicesPrepaymentDebitMemo extends Mixins(
  MNotificationVue
) {
  formatterNumber = formatterNumber;
  reverseFormatNumber = reverseFormatNumber;
  useLocalFilter = useLocalFilter;
  formatTax = formatTaxNumber;
  formatTaxtoNumber = formatTaxNumbertoNumber;
  ALLOW_TO_CANCEL = ALLOW_TO_CANCEL;
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;

  // infer mutation type
  setFormMeta!: (payload: FormMeta) => void;
  setDetail!: (payload: InvoiceApPrepaymentData) => void;
  setSubtotal!: (payload: number) => void;
  setTaxRate!: (payload: number) => void;
  setTotalTax!: (payload: number) => void;
  setTotal!: (payload: number) => void;
  storeFormMeta!: FormMeta;
  storeSubtotal!: number;
  storeTotalTax!: number;
  storeTotalPayment!: number;
  storeTaxRate!: number;
  storeDetail!: InvoiceApPrepaymentData;
  storePreference!: ResponsePreference[];

  show = {
    submit: true,
    returnNumber: false,
    rate: false,
    poNumber: true,
    status: false,
  };

  form!: WrappedFormUtils;
  formTable!: WrappedFormUtils;

  columnTabPane: Array<string> = [];
  activeTabPane = "Details";

  optBranch: Array<Option<DataWarehouseBranch>> = [];
  optCurrency: Array<Option<DataMasterCurrency>> = [];
  optAssetCode: Array<Option<DataListMasterAsset>> = [];

  disabled = {
    assetCode: false,
    submit: true,
    approve: true,
    invoiceType: false,
    supplierName: false,
    supplierBillTo: false,
    payableAccount: false,
    branch: false,
    currency: false,
    invoiceDate: false,
    accountingDate: false,
    invoiceDescription: false,
    rate: false,
    returnNumber: true,
    amount: false,
    poNumber: false,
    termOfPayments: false,
    taxType: false,
    customerTaxType: false,
    taxInvoiceDate: true,
    paidStatus: false,
    taxRegistrationName: false,
    taxRegistrationNumber: false,
    taxCode: false,
    lineAccount: false,
    taxInvoiceNumber: false,
  };

  loading = {
    supplierName: false,
    payableAccount: false,
    branch: false,
    currency: false,
    submit: false,
    reject: false,
    approve: false,
    customerTax: false,
    paidStatus: false,
    poNumber: false,
    cancel: false,
    assetCode: false,
  };

  dataListPoNumber: Array<Option<DataResponseGetListPurchaseOrder>> = [];
  dataListPayableAccount: Array<Option> = [];
  mode = "";
  dataListSupplier: Array<Option<ContactData>> = [];
  dataListSupplierBillTo: Option[] = [];

  formRules = {
    assetCode: {
      label: "lbl_unit_code",
      decorator: ["assetCode"],
    },
    invoiceType: {
      label: "lbl_invoice_type",
      name: "invoiceType",
      placeholder: "lbl_invoice_type_placeholder",
      decorator: [
        "invoiceType",
        {
          initialValue: "Prepayment",
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    invoiceNumber: {
      label: "lbl_invoice_number",
      name: "invoiceNumber",
      placeholder: "lbl_invoice_number_placeholder",
      decorator: ["invoiceNumber"],
    },
    returnNumber: {
      label: "lbl_return_number",
      name: "returnNumber",
      placeholder: "lbl_return_number_placeholder",
      decorator: ["returnNumber"],
    },
    status: {
      label: "lbl_status",
      name: "status",
      placeholder: "lbl_status_placeholder",
      decorator: ["status"],
    },
    supplierName: {
      label: "lbl_supplier_name",
      name: "supplierName",
      placeholder: "lbl_supplier_name_placeholder",
      decorator: [
        "supplierName",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    supplierBillTo: {
      label: "lbl_supplier_bill_to",
      name: "supplierBillTo",
      placeholder: "lbl_supplier_bill_to_placeholder",
      decorator: [
        "supplierBillTo",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    payableAccount: {
      label: "lbl_payable_account",
      name: "payableAccount",
      placeholder: "lbl_payable_account_placeholder",
      decorator: [
        "payableAccount",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    poNumber: {
      label: "lbl_purchase_order_number",
      name: "poNumber",
      placeholder: "lbl_purchase_order_number_placeholder",
      decorator: ["poNumber"],
    },
    poType: {
      label: "lbl_purchase_order_type",
      name: "poType",
      placeholder: "lbl_purchase_order_type_placeholder",
      decorator: ["poType"],
    },
    branch: {
      label: "lbl_branch",
      name: "branch",
      placeholder: "lbl_branch_placeholder",
      decorator: [
        "branch",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    currency: {
      label: "lbl_currency",
      name: "currency",
      placeholder: "lbl_currency_placeholder",
      decorator: [
        "currency",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    rate: {
      label: "lbl_rate",
      name: "rate",
      placeholder: "lbl_rate_placeholder",
      decorator: [
        "rate",
        {
          initialValue: 1,
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    invoiceDate: {
      label: "lbl_invoice_date",
      name: "invoiceDate",
      placeholder: "lbl_invoice_date_placeholder",
      decorator: [
        "invoiceDate",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    accountingDate: {
      label: "lbl_accounting_date",
      name: "accountingDate",
      placeholder: "lbl_accounting_date_placeholder",
      decorator: ["accountingDate"],
    },
    invoiceDescription: {
      label: "lbl_invoice_description",
      name: "invoiceDescription",
      placeholder: "lbl_invoice_description_placeholder",
      decorator: ["invoiceDescription"],
    },
  };

  docId = "";
  preferences: Array<ResponsePreference> = [];

  beforeCreate(): void {
    this.form = this.$form.createForm(this, {
      name: "createInvoicePrepaymentDebitMemo",
    });
    this.formTable = this.$form.createForm(this, {
      name: "tableInvoicePrepaymentDebitMemo",
    });
  }

  created(): void {
    this.searchData = debounceProcess(this.searchData);
    this.onSearchPayableAccount = debounceProcess(this.onSearchPayableAccount);
    this.onSearchAsset = debounceProcess(this.onSearchAsset);

    this.mode = this.$route.meta.mode;
    this.docId = this.$route.params.id || "";
    this.columnTabPane =
      this.mode === Mode.CREATE
        ? ["Details", "Tax Details"]
        : ["Details", "Tax Details", "Status"];
  }

  mounted(): void {
    this.getListOfPayableAccount(new RequestQueryParams());
    this.getListOfWarehouseBranch();
    this.getListOfMasterCurrency();
    this.getListSupplier();
    this.getListPoNumber();
    if (this.docId) {
      this.getDetail(this.docId);
    } else {
      this.setDefaultForm();
    }
  }

  get allowRejectOrApprove(): boolean {
    return ALLOW_TO_REJECT_OR_APPROVE.includes(this.storeDetail.status);
  }

  get allowCancel(): boolean {
    return ALLOW_TO_CANCEL.includes(this.storeDetail.status);
  }

  searchData(value: string, type): void {
    switch (type) {
      case "branch":
        this.getListOfWarehouseBranch(value);
        break;
      case "currency":
        this.getListOfMasterCurrency(value);
        break;
      case "poNumber":
        this.getListPoNumber(value);
        break;
      case "supplierName":
        this.getListSupplier(value);
        break;
      default:
        break;
    }
  }

  onChangeCurrency(value: string | null): void {
    this.form.setFieldsValue({ currency: value });
    const opt = this.optCurrency.find(e => e.value === value);
    const code = opt?.meta?.currencyCode || "";
    this.show.rate = code !== "IDR";
    this.setFormMeta({ currencyCode: code });

    // find rates
    this.findCurrRate({ from: value || "", to: code });
  }

  onChangeInvoiceDate(value: Moment | null): void {
    this.form.setFieldsValue({
      accountingDate: value,
    });
    this.formTable.setFieldsValue({
      taxInvoiceDate: value,
    });
  }

  /**
   * @description set value to other field which dependent
   * with purchase order
   */
  onChangePo(value: string | null): void {
    const opt = this.dataListPoNumber.find(item => item.value === value);

    this.getTaxRegistrationBySupplier(opt?.meta?.supplierId || "");
    this.form.setFieldsValue({
      poNumber: value || "",
      poType: opt?.meta?.poType || "",
      supplierName: opt?.meta?.supplierName || "",
      supplierBillTo: opt?.meta?.supplierBillToAddress || "",
    });

    const address = opt?.meta?.supplierBillToAddress || "";
    if (address) this.setOptBillAddress([address]);

    this.setFormMeta({
      supplierId: opt?.meta?.supplierId || "",
      poId: opt?.meta?.id || "",
    });

    if (opt && opt.meta) {
      this.findAssetByPo(opt.meta.documentNumber);
    }
  }

  onChangeSupplier(value: string | null): void {
    const opt = this.dataListSupplier.find(e => e.value === value);

    let addressOpt: Array<string> = [];
    let address = "";
    if (opt && opt.meta) {
      addressOpt = opt.meta.addressDataList
        .filter(e => e.billTo)
        .map(e => e.address);

      const data = opt.meta.addressDataList.find(
        e => e.primaryBillTo && e.billTo
      );
      address = data.address || "";
    }

    this.form.setFieldsValue({
      supplierBillTo: address,
      poNumber: "",
    });
    this.setFormMeta({ supplierId: value || "", poId: "", });

    // set address options
    this.setOptBillAddress(addressOpt);

    if (value) {
      this.getTaxRegistrationBySupplier(value);
    }
  }

  setOptBillAddress(options: string[]): void {
    this.dataListSupplierBillTo = options.map(e => ({
      label: e,
      key: e,
      value: e,
    }));
  }

  async getDetail(id: string): Promise<void> {
    try {
      const { findById } = useInvoiceAPPrepayment();
      const res = await findById(id);
      this.setDetail(res);
      this.setFormMeta({
        payableAccId: res.payablesAccountId,
        supplierId: res.supplierId,
        poId: res.purchaseOrderId,
        lineAccId: res.lineAccountId,
        branchId: res.branchWarehouseId,
        assetId: res.assetId,
        currencyCode: res.currency,
      });

      // set visible fields
      this.show.rate = res.currency !== "IDR";
      this.show.returnNumber = res.invoiceType === "Debit Memo";
      this.show.poNumber = res.invoiceType === "Prepayment";
      this.show.submit = false;
      this.show.status = true;

      this.setEditableField(res.status);

      this.form.setFieldsValue({
        assetCode: res.unitCode,
        branch: res.branchWarehouse,
        invoiceType: res.invoiceType,
        invoiceNumber: res.documentNumber,
        currency: res.currency,
        rate: res.currencyRate,
        returnNumber: res.returnNumber || "",
        invoiceDate: res.invoiceDate ? moment(res.invoiceDate) : null,
        supplierName: res.supplierName,
        supplierBillTo: res.supplierBillToAddress,
        accountingDate: res.accountingDate ? moment(res.accountingDate) : null,
        payableAccount: res.payablesAccount,
        invoiceDescription: res.description,
        status: res.status,
        poNumber: res.purchaseOrderNumber,
        poType: res.poType,
      });

      this.formTable.setFieldsValue({
        amount: res.amount,
        termOfPayment: res.termOfPayment,
        lineAccount: res.lineAccount,
        taxType: res.taxType,
        taxCode: res.taxCode,
        taxInvoiceDate: res.taxInvoiceDate ? moment(res.taxInvoiceDate) : null,
        taxInvoiceNumber: res.taxInvoiceNumber,
        taxRegistrationNumber: res.taxRegistrationNumber,
        taxRegistrationName: res.taxRegistrationName,
        prepaymentUsed: res.prepaymentUsed
          ? currencyFormat(res.prepaymentUsed)
          : "",
        total: res.total ? currencyFormat(res.total) : "",
        remainingPrepayment: res.remainingAmount,
        prepaymentPaid: res.paidAmount ? currencyFormat(res.paidAmount) : "",
        paidStatus: res.status,
      });

      // set subtotal, total tax, total, tax rate
      this.setSubtotal(res.subTotal || 0);
      this.setTotalTax(res.totalTax || 0);
      this.setTotal(res.total || 0);
      this.setTaxRate(res.taxRate || 0);
    } catch (error) {
      this.showNotifError("notif_process_fail");
    }
  }

  setEditableField(status: AP_PREPAYMENT_STATUS): void {
    this.disabledAll();

    // allow edit fields if need approval
    if (status !== "Need Approval") return;
    for (const key in this.disabled) {
      this.disabled[key] = !(key !== "invoiceType" && key !== "payableAccount");
    }
  }

  disabledAll(): void {
    for (const key in this.disabled) {
      this.disabled[key] = true;
    }
  }

  getListPoNumber(value = ""): void {
    const { findAll } = usePurchaseOrder();
    const params = new RequestQueryParams();
    const builder = new SearchBuilder();
    const defaultQ: Array<string> = [
      builder
        .push(["status", "Approved"])
        .or()
        .push(["status", "Received"])
        .or()
        .push(["status", "Partially Received"])
        .build(),
    ];
    if (value) {
      defaultQ.push(
        builder.push(["documentNumber", value], { like: "both" }).build()
      );
    }
    params.search = defaultQ.join(builder.AND);
    this.loading.poNumber = true;
    findAll(params)
      .then(res => {
        this.dataListPoNumber = res.data.map<
          Option<DataResponseGetListPurchaseOrder>
        >(item => ({
          meta: item,
          value: item.documentNumber,
          label: item.documentNumber || "-",
          key: item.id,
        }));
      })
      .finally(() => (this.loading.poNumber = false));
  }

  validateForm(type: "approve" | "submit"): void {
    this.form.validateFields(err => {
      if (err) return;

      this.formTable.validateFields(errTable => {
        if (errTable) return;

        if (type === "approve") this.handleApprove(this.docId);
        else if (type === "submit") this.handleSubmit();
      });
    });
  }

  buildReqPayload(): CreateInvoiceApPrepayment {
    const {
      payableAccId = "",
      branchId = "",
      lineAccId = "",
      poId = "",
      supplierId = "",
      assetId = "",
    } = this.storeFormMeta;
    const { buildCreateDto } = useInvoiceAPPrepayment();
    const header = this.form.getFieldsValue();
    const details = this.formTable.getFieldsValue();
    const payload = buildCreateDto({
      accountingDate: header.accountingDate.format(),
      amount: details.amount,
      assetCode: assetId, // use secureId
      branch: branchId,
      currency: header.currency, // use currency code as payload
      currencyRate: header.rate || 1,
      description: header.invoiceDescription,
      invoiceDate: header.invoiceDate.format(),
      invoiceType: "Prepayment", // hardcoded intended, part of business rules
      lineAccount: lineAccId,
      payableAccount: payableAccId,
      poNumber: poId,
      supplierBillTo: header.supplierBillTo,
      supplierName: supplierId,
      taxCode: details.taxCode,
      taxInvoiceDate: details.taxInvoiceDate.format(),
      taxInvoiceNumber: details.taxInvoiceNumber,
      taxRegistrationName: details.taxRegistrationName,
      taxRegistrationNumber: details.taxRegistrationNumber,
      taxType: details.taxType,
      termOfPayment: details.termOfPayment,
      status: header.status,
    });
    return payload;
  }

  handleSubmit(): void {
    const { createInvoice } = useInvoiceAPPrepayment();
    const payload = this.buildReqPayload();
    payload.status = "Need Approval"; // intended, part of business flow

    this.loading.submit = true;
    createInvoice(payload)
      .then(response => {
        this.showNotifSuccess("notif_submit_success", {
          documentNumber: response.documentNumber,
        });
        this.$router.push(
          "/account-payables/invoices/invoices-prepayment-debit-memo"
        );
      })
      .finally(() => {
        this.loading.submit = false;
      });
  }

  handleApprove(id: string): void {
    const { approveInvoice } = useInvoiceAPPrepayment();
    const payload = this.buildReqPayload();
    this.loading.approve = true;
    approveInvoice(id, payload)
      .then(response => {
        this.showNotifSuccess("notif_approve_success", {
          documentNumber: response.documentNumber,
        });
        this.$router.push({ name: "account-payables.invoice-prepayment.list" });
      })
      .finally(() => {
        this.loading.approve = false;
      });
  }

  handleReject(id: string): void {
    const { rejectInvoice } = useInvoiceAPPrepayment();
    this.loading.reject = true;
    rejectInvoice(id)
      .then(res => {
        this.showNotifSuccess("notif_reject_success", {
          documentNumber: res.documentNumber,
        });
        this.$router.push({ name: "account-payables.invoice-prepayment.list" });
      })
      .finally(() => {
        this.loading.reject = false;
      });
  }

  findCurrRate(field: { from: string; to: string }): void {
    const { findConversion } = useCurrency();
    const ONE = 1;

    // guard if params falsy
    if (!field.from || !field.to) {
      this.form.setFieldsValue({
        rate: ONE,
      });
      return;
    }

    findConversion(field.from, field.to).then(res => {
      const [curr] = res.data;
      this.form.setFieldsValue({
        rate: curr?.rate || ONE,
      });
    });
  }

  onSearchPayableAccount(search = ""): void {
    const { filterBy } = useCoa();
    const params = new RequestQueryParams();
    params.search = filterBy({ code: search, description: search });

    this.getListOfPayableAccount(params);
  }

  /**
   * get list of payables account
   */
  getListOfPayableAccount(params: RequestQueryParamsModel): void {
    const { findAll, mapToOption, filterBy } = useCoa();
    const builder = new SearchBuilder();
    const defaultQ: string[] = [
      builder.push(["parentAccount.code", "2"], { like: "end" }).build(),
    ];

    if (this.storeFormMeta && this.storeFormMeta.currencyCode) {
      defaultQ.push(
        filterBy({ currencyCode: this.storeFormMeta.currencyCode })
      );
    }

    const copy = { ...params };
    if (copy.search) {
      defaultQ.unshift(copy.search);
    }

    params.search = defaultQ.join(builder.AND);

    this.loading.payableAccount = true;
    findAll(params)
      .then(res => {
        this.dataListPayableAccount = mapToOption(res.data);
      })
      .finally(() => (this.loading.payableAccount = false));
  }

  /**
   * @param value currency code ex: IDR
   */
  getListOfMasterCurrency(value = ""): void {
    const { findAll } = useCurrency();
    const params = new RequestQueryParams();
    this.loading.currency = true;
    if (value) params.search = `currencyCode~*${value}*`;
    findAll(params)
      .then(res => {
        this.optCurrency = res.data.map<Option<DataMasterCurrency>>(item => ({
          label: item.currencyCode || "-",
          value: item.currencyCode || "",
          key: item.id || "",
          meta: item,
        }));
      })
      .finally(() => {
        this.loading.currency = false;
      });
  }

  handleChangeMenu(value: string): void {
    this.activeTabPane = value;
  }

  getTaxRegistrationBySupplier(id: string): void {
    if (!id) return;
    const { findById } = useContactData();
    findById(id).then(res => {
      this.formTable.setFieldsValue({
        taxRegistrationNumber: res.taxRegisNumber,
        taxRegistrationName: res.taxRegisName,
      });
    });
  }

  getListSupplier(value = ""): void {
    const { findSuppliers, toOptionsName } = useContactData();
    const builder = new SearchBuilder();
    const params = new RequestQueryParams();
    if (value) {
      params.search = builder
        .push(["firstName", value], { like: "both" })
        .or()
        .push(["lastName", value], { like: "both" })
        .build();
    }
    this.loading.supplierName = true;
    findSuppliers(params)
      .then(response => {
        this.dataListSupplier = toOptionsName(response);
      })
      .finally(() => (this.loading.supplierName = false));
  }

  getListOfWarehouseBranch(value = ""): void {
    const { toOptions, findAll } = useBranch();
    const params = new RequestQueryParams();
    if (value) params.search = `name~*${value}*`;
    this.loading.branch = true;
    findAll(params)
      .then(res => {
        this.optBranch = toOptions(res.data);
      })
      .finally(() => (this.loading.branch = false));
  }

  back(): void {
    this.$confirm({
      title: this.$t("lbl_leave_page"),
      onOk: () => {
        this.$router.push({ name: "account-payables.invoice-prepayment.list" });
      },
      onCancel() {
        return;
      },
    });
  }

  async handleCancel(id: string): Promise<void> {
    const { cancelInvoice } = useInvoiceAPPrepayment();
    try {
      this.loading.cancel = true;
      const { documentNumber } = await cancelInvoice(id);
      this.showNotifSuccess("notif_cancel_success", { documentNumber });
      this.$router.push({ name: "account-payables.invoice-prepayment.list" });
    } catch (error) {
      this.showNotifError("notif_cancel_fail");
    } finally {
      this.loading.cancel = false;
    }
  }

  async fetchPreferences(): Promise<ResponsePreference[]> {
    const { findAll } = usePreferences();
    const response = await findAll();
    this.preferences = response;
    return response;
  }

  getPrefByKey(key: string): ResponsePreference | undefined {
    return this.preferences.find(e => e.key === key);
  }

  async setDefaultForm(): Promise<void> {
    try {
      const builder = new SearchBuilder();
      const { findById } = useCurrency();
      const { findAll: findAllCoa, toLabel: toLabelCoa } = useCoa();

      await this.fetchPreferences();

      //#region set default currency
      const prefCurr = this.getPrefByKey("feature_base_currency")?.value || "";
      const curr = await findById(prefCurr);
      //#endregion

      //#region set default payable account
      const prefPayAcc =
        this.getPrefByKey("account_setup_account_payables")?.value || "";

      const prefLineAcc =
        this.getPrefByKey("account_setup_prepayment_ap")?.value || "";

      const dataCoa = await findAllCoa({
        search: builder
          .push(["secureId", prefPayAcc])
          .or()
          .push(["secureId", prefLineAcc])
          .build(),
      });
      const [pay, line] = dataCoa.data;
      const payAcc = pay
        ? toLabelCoa({ code: pay.code, desc: pay.description })
        : "";
      const lineAcc = line
        ? toLabelCoa({ code: line.code, desc: line.description })
        : "";

      this.form.setFieldsValue({
        currency: curr.currencyCode,
        payableAccount: payAcc,
      });
      this.formTable.setFieldsValue({
        lineAccount: lineAcc,
      });
      //#endregion

      this.setFormMeta({
        payableAccId: prefPayAcc,
        lineAccId: prefLineAcc,
        currencyCode: curr.currencyCode,
      });
    } catch (error) {
      this.showNotifError("notif_process_fail");
    }
  }

  findAssetByPo(poNumber: string | undefined): void {
    const { filterBy } = useAsset();
    const params = new RequestQueryParams();
    params.search = filterBy({ poNo: poNumber });
    this.fetchAsset(params);
  }

  onSearchAsset(search = ""): void {
    const builder = new SearchBuilder();
    const { filterBy } = useAsset();
    const { poNumber } = this.form.getFieldsValue();
    const params = new RequestQueryParams();
    const byUnit: string = filterBy({ unitCode: search });
    const q: string[] = [];

    if (byUnit) {
      q.push(byUnit);
    }

    if (search) {
      q.unshift(builder.push(["purchaseOrderNumber", poNumber]).build());
    }

    params.search = q.join(builder.AND);

    this.fetchAsset(params);
  }

  fetchAsset(params: RequestQueryParamsModel): void {
    const { findAllMasterAsset, toOptions } = useAsset();
    this.loading.assetCode = true;
    findAllMasterAsset(params)
      .then(res => {
        this.optAssetCode = toOptions(res.data);
      })
      .finally(() => {
        this.loading.assetCode = false;
      });
  }
}
