




























































































































































































































































































































































































































































































































































import SearchBuilder from "@/builder/SearchBuilder";
import EmployeeSelect from "@/components/custom/select/EmployeeSelect.vue";
import { debounce } from "@/helpers/debounce";
import { useTax } from "@/hooks/tax";
import useAccountingCalculation from "@/hooks/useAccountingCalculation";
import useBlob from "@/hooks/useBlob";
import { PurchaseOrderMapper } from "@/mapper/PurchaseOrder.mapper";
import MNotification from "@/mixins/MNotification.vue";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { OptionModel } from "@/models/constant/interface/common.interface";
import {
  DATE_FORMAT_YYYY_MM_DD,
  DEFAULT_DATE_FORMAT,
} from "@/models/constants/date.constant";
import { Mode } from "@/models/enums/global.enum";
import { AssetStateEnum } from "@/models/enums/master-asset.enum";
import { Messages } from "@/models/enums/messages.enum";
import { PURCHASE_ORDER_TYPE } from "@/models/enums/purchase-order.enum";
import { TAX_CALCULATION } from "@/models/enums/tax.enum";
import { DataListMasterAsset } from "@/models/interface/assets.interface";
import {
  ContactData,
  ResponseListMaster,
} from "@/models/interface/contact.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  DataListProduct,
  DataWarehouseBranch,
} from "@/models/interface/logistic.interface";
import { IPreferencesResponseDto } from "@/models/interface/preference";
import {
  PurchaseOrderCreateDto,
  PurchaseOrderFormProductState,
  PurchaseOrderFormState,
  PurchaseOrderResponseDto,
  PurchaseOrderUpdateDto,
} from "@/models/interface/purchase-order";
import { DataResponseGetListPurchaseOrder } from "@/models/interface/purchase.interface";
import { DataMasterCurrency } from "@/models/interface/settings.interface";
import { ColumnTableCustom } from "@/models/interface/util.interfase";
import { assetsServices } from "@/services/assets.service";
import { contactServices } from "@/services/contact.service";
import { logisticServices } from "@/services/logistic.service";
import { masterServices } from "@/services/master.service";
import { productService } from "@/services/product.service";
import { purchaseServices } from "@/services/purchase.service";
import { settingsServices } from "@/services/settings.service";
import { taxBaseVariableService } from "@/services/tax-base-variable.service";
import { LabelInValue } from "@/types";
import { StringUtils } from "@/utils/StringUtils";
import {
  changeCurrencytoNumeric,
  currencyFormat,
} from "@/validator/globalvalidator";
import { AddressDataDto } from "@interface/contact-data";
import { WrappedFormUtils } from "ant-design-vue/types/form/form";
import { UploadFile } from "ant-design-vue/types/upload";
import Decimal from "decimal.js-light";
import moment, { Moment } from "moment";
import printJS from "print-js";
import { Component, Mixins } from "vue-property-decorator";

enum STATUS {
  New = "New",
  Draft = "Draft",
  Need_Approval = "Need Approval",
  Approved = "Approved",
  Rejected = "Rejected",
  Cancelled = "Cancelled",
  Closed = "Closed",
  Updated = "Updated",
}

const PRODUCT_CODE_INDEX = 0;
const PRODUCT_NAME_INDEX = 1;
const TAX_RATE_INDEX = 2;
const INCLUSIVE_TAX_RATE_INDEX = 3;
const UOM_INDEX = 4;
const LOCATION_NEEDED_INDEX = 5;
const UNIT_CODE_INDEX = 6;

@Component({
  components: {
    EmployeeSelect,
  },
  watch: {
    "$store.state.preferenceStore.dataPreference": {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue && newValue.length > 0) {
          this.setBaseCurrencyCreate();
        }
      },
    },
    "$store.state.innerWidth": {
      immediate: true,
      handler(newValue) {
        this.handleResponsiveColumnByWidth(newValue);
      },
    },
    mode: {
      immediate: true,
      deep: true,
      handler() {
        if (this.$store.state.preferenceStore.dataPreference.length > 0) {
          this.setBaseCurrencyCreate();
        }
      },
    },
    currencyFrom: {
      immediate: true,
      deep: true,
      handler(newVal) {
        const params: RequestQueryParamsModel = {
          search: `fromCurrency.currencyCode~${newVal}_AND_toCurrency.currencyCode~${this.currencyTo}`,
        };
        settingsServices.listOfCurrency(params, "").then(response => {
          if (response.data[0]) {
            if (
              this.dataDetailPo?.currency &&
              this.dataDetailPo?.currency === newVal
            )
              this.setDataForm({
                rate: currencyFormat(this.dataDetailPo?.currencyRate || 1),
              });
            else {
              this.setDataForm({ rate: currencyFormat(response.data[0].rate) });
            }
          } else if (newVal === this.currencyTo) {
            this.setDataForm({ rate: currencyFormat(1) });
          } else {
            this.setDataForm({ rate: currencyFormat(0) });
          }
        });
      },
    },
    taxType: {
      immediate: true,
      deep: true,
      handler() {
        this.calculateLines();
      },
    },
    status: {
      immediate: true,
      deep: true,
      handler() {
        if (this.isNew) {
          this.disabledForm = false;
        } else if (this.isUpdated || this.isNeedApproval) {
          this.disabledForm = false;
          this.dataSource = this.dataSource.map(dataMap => {
            // disabledInput: true,
            // disabledSelect: true,
            // disableByRow: ['productCode', 'productName', 'uom', 'locationNeeded', 'merk', 'qty', 'price', 'taxBase', 'taxRate', 'total', 'description']
            return {
              ...dataMap,
              disabledInput: false,
              disabledSelect: false,
              disableByRow: ["taxBase", "total"],
            };
          });
        } else {
          this.disabledForm = true;
        }
      },
    },
    dataDefaultProduct: {
      immediate: true,
      deep: true,
      handler() {
        this.dataSource.forEach((_, indexSource) => {
          this.dataSource[indexSource].optionCustom[1].option =
            this.dataDefaultProduct.map(dataMap => {
              return {
                ...dataMap,
                customCode: dataMap.code,
                customName: dataMap.name,
              };
            });
          this.dataSource[indexSource].optionCustom[0].option =
            this.dataDefaultProduct.map(dataMap => {
              return {
                ...dataMap,
                customCode: dataMap.code,
                customName: dataMap.name,
                name: dataMap.code,
              };
            });
        });
      },
    },
    dataUnitCode: {
      immediate: true,
      deep: true,
      handler() {
        this.assignUnitCodeFirstLoad();
      },
    },
    dataDetailPo: {
      immediate: true,
      deep: true,
      handler() {
        this.assignUnitCodeFirstLoad();
      },
    },
  },
})
export default class CreatePurchaseOrder extends Mixins(MNotification) {
  debounce = debounce;
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  mode: string = Mode.CREATE;
  form!: WrappedFormUtils;
  poType!: PURCHASE_ORDER_TYPE;
  selectedRowKeys: number[] = [];
  params: RequestQueryParamsModel = {};
  loading = {
    loadingBranch: false,
    loadingSupplier: false,
    loadingPurchaseOrderType: false,
    loadingStatus: false,
    loadingRequester: false,
    loadingCurrency: false,
    loadingTax: false,
    loadingSubmit: false,
    loadingCancel: false,
    loadingApprove: false,
    loadingReject: false,
    loadingUpdate: false,
    loadingDraft: false,
    loadingPrint: false,
  };
  dataDefaultProduct: DataListProduct[] = [];
  dataBranch: DataWarehouseBranch[] = [];
  dataSupplier: ContactData[] = [];
  dataPurchaseOrderNumber: DataResponseGetListPurchaseOrder[] = [];
  dataPurchaseOrderType: ResponseListMaster[] = [];
  dataSource: PurchaseOrderFormProductState[] = [];
  dataRequester: ContactData[] = [];
  dataSupplierBillAddress: Array<AddressDataDto> = [];
  dataCurrency: DataMasterCurrency[] = [];
  dataTax: ResponseListMaster[] = [];
  dataUnitCode: DataListMasterAsset[] = [];
  dataDetailPo: PurchaseOrderResponseDto | null = null;
  defaultPurchaseInclusive: { rate: number; id: string; label: string } = {
    id: "",
    label: "",
    rate: 0,
  };
  fileList: UploadFile[] = [];
  urlForSend = "";
  currencyFrom = "IDR";
  currencyTo = "";
  searchMerkForAddNew = "";
  id = "";
  defaultDescription = "";
  firstLoad = false;
  disabledForm = false;
  modalOpen = false;
  dataListItemsModal = [] as any[];
  deletedPurchaseOrderLines: string[] = [];
  columnsTableModal = [
    {
      title: this.$t("lbl_serial_number"),
      dataIndex: "serialNumber",
      key: "serialNumber",
      width: 150,
      scopedSlots: { customRender: "serialNumber" },
      responsiveColInput: [
        {
          name: "serialNumber",
          placeholder: "Serial Number",
          style: "width: 100%;",
          disabled: "",
        },
        {
          name: "type",
          placeholder: "Type",
          style: "width: 100%;",
          disabled: "",
        },
        {
          name: "specification",
          placeholder: "Specification",
          style: "width: 100%;",
          disabled: "",
        },
        {
          name: "capacity",
          placeholder: "Capacity",
          style: "width: 100%;",
          disabled: "",
        },
      ],
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: 150,
      scopedSlots: { customRender: "type" },
    },
    {
      title: "Specification",
      dataIndex: "specification",
      key: "specification",
      width: 150,
      scopedSlots: { customRender: "specification" },
    },
    {
      title: "Capacity",
      dataIndex: "capacity",
      key: "capacity ",
      width: 150,
      scopedSlots: { customRender: "capacity" },
    },
  ] as ColumnTableCustom[];
  loadingTableModal = false;
  taxType: TAX_CALCULATION = TAX_CALCULATION.EXCLUSIVE;
  status = STATUS.New;
  actualStatusDocument = STATUS.New; // this status can't change

  transactionDate: Moment | null = null;
  isLuxuryGoods = false;
  taxBaseVariable = 1;

  columnsTable = [
    {
      title: "No.",
      dataIndex: "no",
      key: "no",
      width: 120,
      fixed: "left",
      responsiveColSelect: [
        {
          name: "productCode",
          placeholder: "Type for more data",
          style: "width: 200px",
          disabled: false,
          value: "name",
          options: [],
          loading: false,
        },
        {
          name: "productName",
          placeholder: "Type for more data",
          style: "width: 200px",
          disabled: false,
          value: "name",
          options: [],
          loading: false,
        },
        // {
        //   name: 'merk',
        //   placeholder: 'Type for more data',
        //   style: 'width: 100%;',
        //   disabled: false,
        //   value: 'id',
        //   options: [],
        //   loading: false,
        // },
        {
          name: "taxRate",
          placeholder: "Type for more data",
          style: "width: 100%;",
          disabled: false,
          value: "id",
          options: [],
          loading: false,
        },
        {
          name: "inclusiveTax",
          placeholder: "Inclusive Tax Rate",
          style: "width: 100%;",
          disabled: false,
          value: "id",
          labelInValue: true,
          options: [] as OptionModel[],
          loading: false,
          optionLabelProp: "label",
        },
        {
          name: "uom",
          placeholder: "Uom",
          style: "width: 100%",
          // style: "width: 150px",
          disabled: true,
          value: "id",
          options: [],
          loading: false,
        },
        {
          name: "locationNeeded",
          placeholder: "Location Needed",
          style: "width: 100%",
          disabled: false,
          value: "id",
          options: [],
          loading: false,
        },
        {
          name: "unitCode",
          placeholder: "Unit Code",
          style: "width: 100%",
          disabled: false,
          value: "id",
          options: [],
          loading: false,
        },
      ],
      responsiveColSelectAddNew: [
        {
          name: "merk",
          placeholder: "Merk",
          style: "width: 100%;",
          disabled: "",
          value: "id",
          options: [] as any[],
        },
      ],
      responsiveColTextArea: [
        {
          name: "description",
          placeholder: "Description",
          style: "width: 100%",
          disabled: false,
        },
      ],
      responsiveColInput: [
        {
          name: "qty",
          placeholder: "Qty",
          style: "width: 100%",
          disabled: false,
        },
        {
          name: "price",
          placeholder: "Price",
          style: "width: 100%",
          disabled: false,
        },
        {
          name: "taxBase",
          placeholder: "Tax Base",
          style: "width: 100%",
          disabled: true,
        },
        {
          name: "total",
          placeholder: "Total",
          style: "width: 100%",
          disabled: true,
        },
        {
          name: "discountValue",
          placeholder: "Discount Amount",
          style: "width: 100%",
          disabled: false,
        },
      ],
    },
    {
      title: this.$t("lbl_part_number"),
      dataIndex: "productCode",
      key: "productCode",
      width: 250,
      scopedSlots: { customRender: "productCode" },
      fixed: "left",
    },
    {
      title: this.$t("lbl_part_name"),
      dataIndex: "productName",
      key: "productName",
      width: 250,
      scopedSlots: { customRender: "productName" },
      fixed: "left",
    },
    {
      title: this.$t("lbl_uom"),
      dataIndex: "uom",
      key: "uom",
      width: 200,
      scopedSlots: { customRender: "uom" },
    },
    {
      title: this.$t("lbl_location_needed"),
      dataIndex: "locationNeeded",
      key: "locationNeeded",
      width: 250,
      scopedSlots: { customRender: "locationNeeded" },
    },
    {
      title: this.$t("lbl_unit_code"),
      dataIndex: "assetId",
      key: "assetId",
      width: 200,
      scopedSlots: { customRender: "unitCode" },
    },
    {
      title: this.$t("lbl_merk"),
      dataIndex: "merk",
      key: "merk",
      width: 150,
      scopedSlots: { customRender: "merk" },
    },
    {
      title: this.$t("lbl_qty"),
      dataIndex: "qty",
      key: "qty",
      width: 130,
      scopedSlots: { customRender: "qty" },
    },
    {
      title: this.$t("lbl_last_price"),
      dataIndex: "lastPrice",
      key: "lastPrice",
      width: 220,
      scopedSlots: { customRender: "isCurrency" },
    },
    {
      title: this.$t("lbl_price"),
      dataIndex: "price",
      key: "price",
      width: 220,
      scopedSlots: { customRender: "price" },
    },
    {
      title: this.$t("lbl_gross_value"),
      dataIndex: "grossValue",
      key: "grossValue",
      width: 220,
      copedSlots: { customRender: "grossValue" },
    },
    {
      title: this.$t("lbl_tax_base_dpp_idr"),
      dataIndex: "taxBase",
      key: "taxBase",
      width: 220,
      scopedSlots: { customRender: "taxBase" },
    },
    {
      title: this.$t("lbl_tax_rate"),
      dataIndex: "taxRate",
      key: "taxRate",
      width: 200,
      scopedSlots: { customRender: "taxRate" },
    },
    {
      title: this.$t("lbl_discount_amount"),
      dataIndex: "discountValue",
      key: "discountValue",
      width: 200,
      scopedSlots: { customRender: "discountValue" },
    },
    {
      title: this.$t("lbl_total"),
      dataIndex: "total",
      key: "total",
      width: 220,
      scopedSlots: { customRender: "total" },
    },
    {
      title: this.$t("lbl_description"),
      dataIndex: "description",
      key: "description",
      width: 200,
      scopedSlots: { customRender: "description" },
    },
    {
      title: this.$root.$t("lbl_note").toString(),
      dataIndex: "notes",
      key: "notes",
      scopedSlots: { customRender: "buttonAdd" },
      width: 120,
      align: "center",
    },
  ] as ColumnTableCustom[];

  formRules = {
    assignee: {
      label: "lbl_assignee",
      name: "assignee",
      placeholder: "lbl_assignee",
      decorator: [
        "assignee",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    branch: {
      label: "lbl_branch",
      name: "branch",
      placeholder: "lbl_branch_placeholder",
      decorator: [
        "branch",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    requester: {
      label: "lbl_requester",
      name: "requester",
      placeholder: "lbl_requester_placeholder",
      decorator: [
        "requester",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    supplierName: {
      label: "lbl_supplier_name",
      name: "supplierName",
      placeholder: "lbl_supplier_name_placeholder",
      decorator: [
        "supplierName",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    supplierBillAddress: {
      label: "lbl_supplier_bill_address",
      name: "supplierBillAddress",
      placeholder: "lbl_supplier_bill_address_placeholder",
      decorator: [
        "supplierBillAddress",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    currency: {
      label: "lbl_currency",
      name: "currency",
      placeholder: "lbl_currency_placeholder",
      decorator: [
        "currency",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    rate: {
      label: "lbl_rate",
      name: "rate",
      placeholder: "lbl_rate_placeholder",
      decorator: [
        "rate",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    tax: {
      label: "lbl_tax",
      name: "tax",
      placeholder: "lbl_tax_placeholder",
      decorator: [
        "tax",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    refNumber: {
      label: "lbl_refNumber",
      name: "refNumber",
      placeholder: "lbl_refNumber_placeholder",
      decorator: [
        "refNumber",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    description: {
      label: "lbl_description",
      name: "description",
      placeholder: "lbl_description_placeholder",
      decorator: [
        "description",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    date: {
      label: "lbl_date",
      name: "date",
      placeholder: "lbl_date_placeholder",
      decorator: [
        "date",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    purchaseRequisitionNumber: {
      label: "lbl_purchase_requisition_number",
      name: "purchaseRequisitionNumber",
      placeholder: "lbl_purchase_requisition_number_placeholder",
      decorator: [
        "purchaseRequisitionNumber",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    purchaseOrderNumber: {
      label: "lbl_purchase_order_number",
      name: "purchaseOrderNumber",
      placeholder: "lbl_purchase_order_number_placeholder",
      decorator: [
        "purchaseOrderNumber",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    purchaseOrderType: {
      label: "lbl_purchase_order_type",
      name: "purchaseOrderType",
      placeholder: "lbl_purchase_order_type_placeholder",
      decorator: [
        "purchaseOrderType",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    attachment: {
      label: "lbl_attachment",
    },
    note: {
      label: "lbl_note",
      name: "note",
      placeholder: "lbl_note_placeholder",
      decorator: [
        "note",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    status: {
      label: "lbl_status",
      name: "status",
      placeholder: "lbl_status_placeholder",
      decorator: [
        "status",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    arrivalEstimated: {
      label: "lbl_arrival_estimated",
      name: "arrivalEstimated",
      placeholder: "lbl_arrival_estimated_placeholder",
      decorator: [
        "arrivalEstimated",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    totalQty: {
      label: "lbl_total_qty",
      name: "totalQty",
      placeholder: "lbl_total_qty_placeholder",
      decorator: ["totalQty"],
    },
    total: {
      label: "lbl_sub_total",
      name: "total",
      placeholder: "lbl_total_placeholder",
      decorator: [
        "total",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    totalDiscount: {
      label: "lbl_total_discount",
      name: "totalDiscount",
      placeholder: "lbl_total_discount_placeholder",
      decorator: [
        "totalDiscount",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    totalTax: {
      label: "lbl_total_tax",
      name: "totalTax",
      placeholder: "lbl_total_tax_placeholder",
      decorator: [
        "totalTax",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    grandTotal: {
      label: "lbl_grand_total",
      name: "grandTotal",
      placeholder: "lbl_grand_total_placeholder",
      decorator: [
        "grandTotal",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    isLuxuryGoods: {
      label: "lbl_luxury_goods",
      name: "isLuxuryGoods",
      placeholder: "lbl_luxury_goods",
      decorator: ["isLuxuryGoods", { valuePropName: "checked" }],
    },
  };

  setDataForm(values): void {
    for (const key in values) {
      this.form.getFieldDecorator(key, {
        initialValue: values[key],
      });
    }
    this.form.setFieldsValue(values);
  }

  redirectToDetailSubmit() {
    this.$router.go(0);
  }

  onSubmit(
    type: "draft" | "update" | "submit" | "cancel" | "approve" | "reject"
  ): void {
    const merkMandatory = this.dataSource.every(
      item => item.merk !== undefined
    );
    const merkTypeSubmit =
      type === "approve" || type === "submit" || type === "update";

    this.form.validateFields((err, res) => {
      if (err) return;
      if (merkTypeSubmit && !merkMandatory) {
        this.$notification.error({
          message: this.$t("lbl_merk") as string,
          description: this.$t("notif_column_is_mandatory", {
            message: "",
          }) as string,
        });
        return;
      }

      switch (type) {
        case "submit":
          this.handleSubmitPo(res);
          break;
        case "update":
          this.handleUpdatePo(res);
          break;
        case "draft":
          this.handleCreateDraft(res);
          break;
        case "reject":
          this.handleReject();
          break;
        case "approve":
          this.handleApprove(res);
          break;
        case "cancel":
          this.handleCancelPo();
          break;
        default:
          break;
      }
    });
  }

  async handlePrint(): Promise<void> {
    const { toObjectUrl } = useBlob();
    try {
      this.loading.loadingPrint = true;
      const response = await purchaseServices.printPurchaseOrder(this.id);
      const url = toObjectUrl(response);
      printJS(url);
    } catch {
      this.showNotifError("notif_print_fail");
    } finally {
      this.loading.loadingPrint = false;
    }
  }

  handleChangeDescription(event) {
    this.defaultDescription = event.target.value;
  }

  handleSelect(value, key, col): void {
    const findColumn = this.columnsTable.find(
      c => c.responsiveColSelect
    )?.responsiveColSelect;
    switch (col) {
      case "productCode":
        if (value) {
          const dataFind = this.dataSource[key].optionCustom[0].option.find(
            findData => findData.name === value
          );
          // this.dataSource[key].idProduct = value
          this.dataSource[key].idProduct = dataFind.id;
          this.dataSource[key].productCode = dataFind.customCode
            ? dataFind.customCode
            : "";
          this.dataSource[key].productName = dataFind.customName
            ? dataFind.customName
            : "";
          this.getDetailProduct(dataFind.id, key);
        } else {
          this.dataSource[key].productCode = value;
          this.dataSource[key].productName = "";
          this.dataSource[key].uom = "";
          this.dataSource[key].merk = "";
          this.dataSource[key].idProduct = "";
          this.dataSource[key].lastPrice = 0;
        }
        break;
      case "productName":
        if (value) {
          const dataFind = this.dataSource[key].optionCustom[1].option.find(
            findData => findData.name === value
          );
          // this.dataSource[key].idProduct = value
          this.dataSource[key].idProduct = dataFind.id;
          this.dataSource[key].productCode = dataFind.customCode
            ? dataFind.customCode
            : "";
          this.dataSource[key].productName = dataFind.customName
            ? dataFind.customName
            : "";
          this.getDetailProduct(dataFind.id, key);
        } else {
          this.dataSource[key].productCode = "";
          this.dataSource[key].productName = value;
          this.dataSource[key].uom = "";
          this.dataSource[key].merk = "";
          this.dataSource[key].idProduct = "";
          this.dataSource[key].lastPrice = 0;
        }
        break;
      case "taxRate":
        if (value && findColumn) {
          this.dataSource[key].taxRate = value;
          this.dataSource[key].taxRatePercent = findColumn[
            TAX_RATE_INDEX
          ].options.filter(dataFilter => dataFilter.id === value)[0]?.rate;
          this.calculateLines();
        } else {
          this.dataSource[key].taxRatePercent = 0;
          this.dataSource[key].taxRate = "";
        }
        break;
      case "inclusiveTax":
        this.handleChangeInclusiveTaxRate(value, findColumn, key);
        break;
      case "unitCode":
        this.dataSource[key].assetId = value;
        break;
      default:
        this.dataSource[key][col] = value;
        break;
    }
  }

  handleChangeInclusiveTaxRate(value: LabelInValue, findColumn, key: number) {
    if (value && findColumn) {
      this.dataSource[key].inclusiveTax = {
        label: value.label,
        key: value.key,
      };
      const rate =
        findColumn[INCLUSIVE_TAX_RATE_INDEX].options.filter(
          dataFilter => dataFilter.id === value.key
        )[0]?.rate || 0;

      this.dataSource[key].inclusiveTaxRate = rate;

      this.calculateLines();
    } else {
      this.dataSource[key].inclusiveTax = undefined;
      this.dataSource[key].inclusiveTaxRate = 0;
    }
  }

  handleInput(value, key, _column, columnName, event) {
    let tempValue = value;
    if (event === "onFocus") {
      // format numeric
      if (
        columnName === "qty" ||
        columnName === "price" ||
        columnName === "discountValue"
      )
        tempValue = changeCurrencytoNumeric(value);
      this.dataSource[key] = {
        ...this.dataSource[key],
        [columnName]: tempValue,
      };
      this.dataSource = this.dataSource.slice();
    }
    if (event === "onBlur") {
      if (
        columnName === "qty" ||
        columnName === "price" ||
        columnName === "discountValue" ||
        columnName === "grossValue"
      )
        tempValue = currencyFormat(value);
      this.dataSource[key] = {
        ...this.dataSource[key],
        [columnName]: tempValue,
      };
      this.dataSource = this.dataSource.slice();
      this.calculateLines();
    }
    if (event === "onChange") {
      this.dataSource[key] = {
        ...this.dataSource[key],
        [columnName]: tempValue,
      };
      this.dataSource = this.dataSource.slice();
      this.calculateLines();
    }
  }

  handleSearchSelectTable(value, key, colName) {
    switch (colName) {
      case "productCode":
        this.getProduct(value, false, key, colName);
        break;
      case "productName":
        this.getProduct(value, false, key, colName);
        break;
      case "merk":
        this.searchMerkForAddNew = value; // this for add new if need
        this.getListOfMerk(value);
        break;
      case "taxRate":
        this.getTaxRate(value);
        break;
      case "inclusiveTaxRate":
        this.getInclusiveTaxRate(value);
        break;
      case "locationNeeded":
        this.getListWarehouseLocation(value);
        break;
      case "unitCode":
        this.getUnitCode(value);
        break;
      default:
        break;
    }
  }

  handleChangeTax(value) {
    if (value) {
      this.taxType = value;
    } else {
      this.taxType = TAX_CALCULATION.INCLUSIVE;
    }

    this.setInclusiveTaxRateColumn();
    this.calculateLines();
  }

  handleLuxuryGoodsChange(isLuxuryGoods: boolean): void {
    this.isLuxuryGoods = isLuxuryGoods;
    this.form.setFieldsValue({ isLuxuryGoods: isLuxuryGoods });
    this.getTaxBaseVariable();
    this.setInclusiveTaxRateColumn();
    this.calculateLines();
  }

  handleDateChange(date: Moment | null): void {
    this.transactionDate = date;
    this.form.setFieldsValue({ transactionDate: date });
    this.getTaxBaseVariable();
    this.calculateLines();
  }

  setInclusiveTaxRateColumn(): void {
    if (
      StringUtils.compare(this.taxType, TAX_CALCULATION.INCLUSIVE) &&
      !this.isLuxuryGoods
    ) {
      // Add the column
      const inclusiveTaxRateColumn = {
        title: this.$t("lbl_inclusive_tax_rate"),
        dataIndex: "inclusiveTax",
        key: "inclusiveTax",
        width: 220,
        scopedSlots: { customRender: "inclusiveTax" },
      };

      const taxRateIndex = this.columnsTable.findIndex(
        col => col.dataIndex === "taxRate"
      );

      if (taxRateIndex !== -1) {
        this.columnsTable.splice(taxRateIndex + 1, 0, inclusiveTaxRateColumn);
      }

      // Set the default inclusive tax rate per row
      if (this.defaultPurchaseInclusive.id) {
        this.dataSource.forEach(data => {
          data.inclusiveTax = {
            label: this.defaultPurchaseInclusive.label,
            key: this.defaultPurchaseInclusive.id,
          };
          data.inclusiveTaxRate = this.defaultPurchaseInclusive.rate;
        });
      }
    } else {
      // Remove the column
      const columnIndex = this.columnsTable.findIndex(
        col => col.dataIndex === "inclusiveTax"
      );

      if (columnIndex !== -1) {
        this.columnsTable.splice(columnIndex, 1);
      }
    }
  }

  getTaxBaseVariable(): void {
    if (!this.transactionDate) {
      this.calculateLines();
      return;
    }

    taxBaseVariableService
      .getVariable({
        isLuxury: this.isLuxuryGoods,
        transactionDate: this.transactionDate.format(DATE_FORMAT_YYYY_MM_DD),
      })
      .then(res => {
        this.taxBaseVariable = res.value || 1;
        this.calculateLines();
      });
  }

  calculateLines(): void {
    let footer = {
      totalQty: 0,
      total: 0,
      totalTax: 0,
      totalDiscount: 0,
      grandTotal: 0,
    };

    this.dataSource.forEach(data => {
      this.calculateGrossValueAndTotal(data);

      footer.totalQty += changeCurrencytoNumeric(data.qty);
      footer.total += changeCurrencytoNumeric(data.grossValue);
      footer.totalTax += data.taxValue;
      footer.totalDiscount += changeCurrencytoNumeric(data.discountValue);
      footer.grandTotal += changeCurrencytoNumeric(data.total);
    });

    this.form.setFieldsValue({
      totalQty: currencyFormat(footer.totalQty),
      total: currencyFormat(footer.total),
      totalDiscount: currencyFormat(footer.totalDiscount),
      totalTax: currencyFormat(footer.totalTax),
      grandTotal: currencyFormat(footer.grandTotal),
    });
  }

  calculateGrossValueAndTotal(record: PurchaseOrderFormProductState): void {
    const {
      calculateGrossValue,
      calculateGrossAfterDiscount,
      calculateGrossAfterDiscountBeforeTax,
      calculateTaxBase,
      calculateTaxValue,
      calculateTotal,
    } = useAccountingCalculation();

    const price = changeCurrencytoNumeric(record.price);
    const qty = changeCurrencytoNumeric(record.qty);
    const discountLine = changeCurrencytoNumeric(record.discountValue);

    if (this.taxType === TAX_CALCULATION.INCLUSIVE) {
      if (this.isLuxuryGoods) {
        record.inclusiveTax = {
          key: record.taxRate,
          label: record.taxRate,
        };
        record.inclusiveTaxRate = record.taxRatePercent;
      }
    } else {
      record.inclusiveTax = {
        key: this.defaultPurchaseInclusive.id,
        label: this.defaultPurchaseInclusive.label,
      };
      record.inclusiveTaxRate = this.defaultPurchaseInclusive.rate;
    }

    // Calculate
    const grossValue = calculateGrossValue({ price, qty });
    const grossAfterDiscount = calculateGrossAfterDiscount({
      grossValue,
      discountValue: new Decimal(discountLine),
    });
    const grossAfterDiscountBeforeTax = calculateGrossAfterDiscountBeforeTax({
      grossAfterDiscount,
      taxType: this.taxType,
      purchaseInclusiveRate: record.inclusiveTaxRate,
    });
    const taxBase = calculateTaxBase({
      grossAfterDiscountBeforeTax,
      variable: this.taxBaseVariable,
    });
    const taxValue = calculateTaxValue({
      taxBase,
      taxRate: record.taxRatePercent,
    });
    const total = calculateTotal({
      grossAfterDiscountBeforeTax,
      taxValue,
    });

    // Display
    record.grossValue = currencyFormat(grossAfterDiscountBeforeTax);
    record.taxBase = currencyFormat(taxBase);
    record.taxValue = taxValue.toNumber();
    record.total = currencyFormat(total);

    record.grossValueForCalculation = grossAfterDiscountBeforeTax;
    record.taxBaseForCalculation = taxBase;
    record.taxValueForCalculation = taxValue;
    record.totalForCalculation = total;
  }

  handleAddRow(): void {
    const newProduct: PurchaseOrderFormProductState = {
      key: this.dataSource.length,
      no: this.dataSource.length + 1,
      productCode: "",
      productName: "",
      idProduct: "",
      uom: "",
      merk: "",
      qty: "",
      taxBase: 0,
      taxRate: "",
      taxRatePercent: 0,
      total: 0,
      lastPrice: 0,
      price: "",
      id: "",
      taxValue: 0,
      discountValue: "0",
      grossValue: 0,
      assetId: "",
      description: this.defaultDescription,
      locationNeeded: "",
      notes: "",
      itemAssets: [],
      inclusiveTax: undefined,
      inclusiveTaxRate: 0,
      optionCustom: [
        {
          name: "productCode",
          option: this.dataDefaultProduct.map(dataMap => {
            return {
              ...dataMap,
              customCode: dataMap.code,
              customName: dataMap.name,
              name: dataMap.code,
            };
          }),
        },
        {
          name: "productName",
          option: this.dataDefaultProduct.map(dataMap => {
            return {
              ...dataMap,
              customCode: dataMap.code,
              customName: dataMap.name,
            };
          }),
        },
        {
          name: "uom",
          option: [],
        },
      ],
      grossValueForCalculation: new Decimal(0),
      taxBaseForCalculation: new Decimal(0),
      taxValueForCalculation: new Decimal(0),
      totalForCalculation: new Decimal(0),
    };
    if (this.taxType === TAX_CALCULATION.INCLUSIVE) {
      newProduct.inclusiveTax = {
        key: this.defaultPurchaseInclusive.id,
        label: this.defaultPurchaseInclusive.label,
      };
      newProduct.inclusiveTaxRate = this.defaultPurchaseInclusive.rate;
    }
    this.dataSource = [...this.dataSource, newProduct];
  }

  setBaseCurrencyCreate() {
    const tempObject = {
      feature_base_currency: "",
    };
    this.$store.state.preferenceStore.dataPreference.forEach(dataForeach => {
      if (dataForeach.key === "feature_base_currency") {
        tempObject.feature_base_currency = dataForeach.value;
      }
    });
    const params: RequestQueryParamsModel = {
      limit: 10,
      page: 0,
    };
    if (tempObject.feature_base_currency) {
      params.search = `secureId~${tempObject.feature_base_currency}`;
      settingsServices.listOfMasterCurrency(params, "").then(response => {
        this.currencyTo = response.data[0].currencyCode as string;
        if (this.mode && this.mode.toLocaleLowerCase() === Mode.CREATE) {
          this.setDataForm({ currency: response.data[0].currencyCode });
        }
      });
    }
  }

  handleDeleteRow() {
    if (this.selectedRowKeys.length === 0) {
      this.$notification.error({
        description: "Select Data First to Delete",
        message: "Error",
      });
    } else {
      this.$confirm({
        title: `Are you sure want to delete these items?`,
        content: `Total: ${this.selectedRowKeys.length} items will be deleted.`,
        onOk: () => {
          this.deletedPurchaseOrderLines = this.dataSource
            .map((dataMap, index) => {
              if (this.selectedRowKeys.includes(index) && dataMap.id)
                return dataMap.id;
              else return "";
            })
            .filter(dataFilter => dataFilter !== "");
          this.dataSource = this.dataSource.filter(
            (_dataFilter, index) => !this.selectedRowKeys.includes(index)
          );
          this.dataSource = this.dataSource.map((dataMap, index) => {
            return { ...dataMap, key: index, no: index + 1 };
          });
          this.selectedRowKeys = [];
        },
        onCancel() {
          return;
        },
      });
    }
  }

  handleChange(info) {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);
    fileList = fileList.map(file => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });
    this.fileList = fileList;
    if (info.file.status === "done") {
      this.pathFile = info.file.response.url;
      this.$notification.success({
        description: `${info.file.name} file uploaded successfully`,
        message: "Success",
        duration: 30,
      });
    } else if (info.file.status === "error") {
      this.$notification.error({
        description: `${info.file.name} file upload failed.`,
        message: "Error",
        duration: 30,
      });
    }
  }

  beforeUpload(file) {
    const isLt5M = file.size;
    if (isLt5M >= 5242880) {
      this.$notification.error({
        description: "File must smaller than 5MB!",
        message: "Error",
        duration: 30,
      });
    }
    return isLt5M;
  }

  customRequest(options) {
    const data = new FormData();
    data.append("file", options.file);
    assetsServices
      .createUploadFile(data, "asset")
      .then(response => {
        options.onSuccess(options);
        this.urlForSend = response.url;
      })
      .catch(err => {
        options.onError(err, options);
      });
  }

  async getDetailProduct(idProduct, keyDataSource) {
    const params: RequestQueryParamsModel = {};
    logisticServices.getDetailProduct(idProduct).then(async response => {
      try {
        const findColumn = this.columnsTable.find(
          c => c.responsiveColSelect
        )?.responsiveColSelect;
        const findData = response.uomConversions.find(
          dataFind => dataFind.uomSource === response.baseUnit
        );
        this.dataSource[keyDataSource].merk = response.merk;
        this.dataSource[keyDataSource].taxRate = response.purchaseTaxId;
        this.dataSource[keyDataSource].taxRatePercent =
          findColumn[TAX_RATE_INDEX].options.find(
            c => c.id === response.purchaseTaxId
          )?.rate || 0;
        if (findData) {
          this.dataSource[keyDataSource].uom = findData.uomSourceId;
          this.dataSource[keyDataSource].optionCustom[2].option = [
            {
              ...findData,
              id: findData.uomSourceId,
              name: findData.uomSource,
            },
          ];
        }
        params.productId = this.dataSource[keyDataSource].idProduct;
        params.uomId = this.dataSource[keyDataSource].uom;
        const data = await purchaseServices.getListLastPrice(params);
        this.dataSource[keyDataSource].lastPrice = data.lastPrice;
      } catch {
        this.dataSource[keyDataSource].lastPrice = 0;
      }
    });
  }

  handleInputModal(
    value,
    key,
    objectColInput,
    _objectColInputName: string,
    _onEvt: string
  ) {
    this.dataListItemsModal[key][objectColInput.name] = value;
    this.dataListItemsModal = this.dataListItemsModal.slice();
  }
  saveDataModal(): void {
    this.modalOpen = false;
    this.dataSource[this.indexRow].itemAssets = this.dataListItemsModal;
    debounce(() => {
      this.dataSource[this.indexRow].notes =
        this.dataSource[this.indexRow].itemAssets[0].serialNumber;
    }, 100);
  }
  handleCancel(): void {
    this.modalOpen = false;
  }

  handleAddData(response): void {
    this.indexRow = response.data.key;
    this.modalOpen = true;
    if (
      // this.dataSource[response.data.key].itemAssets.length !=
      // response.data.qty
      this.dataSource[response.data.key].itemAssets.length === 0 &&
      changeCurrencytoNumeric(response.data.qty) > 0
    ) {
      this.dataListItemsModal = [];
      for (let index = 0; index < 1; index++) {
        const generateSerialNumber = {
          serialNumber: null,
          key: index,
          type: null,
          capacity: null,
          specification: null,
        };
        this.dataListItemsModal.push(generateSerialNumber);
        this.dataSource[response.data.key].itemAssets = this.dataListItemsModal;
      }
    } else {
      this.dataListItemsModal = this.dataSource[response.data.key].itemAssets;
    }
  }

  addNew() {
    if (this.searchMerkForAddNew) {
      let payload = {
        name: this.searchMerkForAddNew,
      };
      productService.addMerk(payload).then(() => {
        this.searchMerkForAddNew = "";
        this.getListOfMerk("");
      });
    } else {
      this.$notification.error({
        message: "Error",
        description: "Please Add merk Name First",
      });
    }
  }

  getListOfMerk(value: string) {
    let params = {
      limit: 10,
      page: 0,
      sorts: "createdDate:desc",
    } as RequestQueryParamsModel;
    if (value) params.search = `name~*${value}*`;
    productService.listMerk(params).then((res: any) => {
      const findColumn = this.columnsTable.find(
        column => column.responsiveColSelectAddNew
      )?.responsiveColSelectAddNew;
      let dataobj = {} as any;
      const arrTemp = [] as any[];
      res.data.forEach((element, idx) => {
        dataobj = {
          key: idx,
          name: element,
          id: element,
        };
        arrTemp.push(dataobj);
      });

      findColumn[0].options = arrTemp;
    });
  }

  getTaxRate(valueSearch) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
      search: `taxType~VAT_IN`,
    };
    if (valueSearch)
      params.search = `code~*${valueSearch}*_OR_description~*${valueSearch}*_AND_taxType~VAT_IN`;
    settingsServices.listOfTax(params, "").then(response => {
      const findColumn = this.columnsTable.find(
        c => c.responsiveColSelect
      )?.responsiveColSelect;
      if (findColumn)
        findColumn[TAX_RATE_INDEX].options = response.data.map(dataMap => {
          return { ...dataMap, name: dataMap.description, id: dataMap.id };
        });
    });
  }

  getInclusiveTaxRate(valueSearch) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
      search: `taxType~VAT_IN`,
    };
    if (valueSearch)
      params.search = `code~*${valueSearch}*_OR_description~*${valueSearch}*_AND_taxType~VAT_IN`;
    settingsServices.listOfTax(params, "").then(response => {
      const findColumn = this.columnsTable.find(
        c => c.responsiveColSelect
      )?.responsiveColSelect;
      if (findColumn)
        findColumn[INCLUSIVE_TAX_RATE_INDEX].options = response.data.map(
          dataMap => {
            return {
              ...dataMap,
              key: dataMap.id,
              name: dataMap.description,
            };
          }
        );
    });
  }

  getRequester(valueSearch) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
      search: `employee~true_AND_active~true`,
    };
    if (valueSearch)
      params.search = `firstName~*${valueSearch}*_OR_lastName~*${valueSearch}*_AND_employee~true_AND_active~true`;
    this.loading.loadingRequester = true;
    contactServices
      .listContactData(params)
      .then(res => {
        this.dataRequester = res.data;
      })
      .finally(() => (this.loading.loadingRequester = false));
  }

  getUnitCode(valueSearch: string) {
    const { purchaseOrderType, branch } = this.form.getFieldsValue();
    if (!purchaseOrderType || !branch) return;

    const params = new RequestQueryParams();
    const criteria = new SearchBuilder()
      .push(["status", AssetStateEnum.RENTED])
      .or()
      .push(["status", AssetStateEnum.READY])
      .or()
      .push(["status", AssetStateEnum.NEED_TO_REPAIR])
      .build();
    const queries: string[] = [criteria];
    const PO_OTHERS = "Others";
    const PO_SERVICE = "Service";
    const isPoOthers =
      this.form.getFieldValue("purchaseOrderType").toUpperCase() ===
      PO_OTHERS.toUpperCase();
    const isPoService =
      this.form.getFieldValue("purchaseOrderType").toUpperCase() ===
      PO_SERVICE.toUpperCase();

    if (isPoService) {
      params.byUser = false;
    }

    if (isPoOthers) {
      queries.push(
        new SearchBuilder()
          .push([
            "assetLocation.warehouse.branchWarehouse.secureId",
            branch.key,
          ])
          .build()
      );
    }
    if (valueSearch) {
      queries.push(
        new SearchBuilder()
          .push(["unitCode", valueSearch], { like: "both" })
          .build()
      );
    }
    params.search = queries.join(SearchBuilder.AND);
    const findColumn = this.columnsTable.find(
      column => column.responsiveColSelect
    )?.responsiveColSelect;
    if (findColumn) findColumn[UNIT_CODE_INDEX].loading = true;
    assetsServices
      .listMasterAsset(params)
      .then(response => {
        if (findColumn) {
          /**
          this variable to store data before map with the purpose to store data unit code in dataSource
          **/
          const tempDataOptionSelected = findColumn[UNIT_CODE_INDEX].options
            .filter(dataFilter =>
              this.dataSource.some(
                dataSome => dataSome.assetId === dataFilter.id
              )
            )
            .slice();
          findColumn[UNIT_CODE_INDEX].options = response.data.map(dataMap => {
            return { ...dataMap, name: dataMap.unitCode };
          });
          findColumn[UNIT_CODE_INDEX].options = [
            ...findColumn[UNIT_CODE_INDEX].options,
            ...tempDataOptionSelected,
          ].filter(
            (dataFilter, index, self) =>
              dataFilter !== undefined &&
              index === self.findIndex(t => t.id === dataFilter.id)
          );
        }
      })
      .finally(() => {
        if (findColumn) findColumn[UNIT_CODE_INDEX].loading = false;
      });
  }

  onBlur(value, form): void {
    if (value.target.value && !this.form.getFieldError(`${form}`)) {
      let dataobj = {};
      dataobj[form] = currencyFormat(value.target.value);
      this.form.setFieldsValue(dataobj);
    }
  }

  onFocus(value, form): void {
    if (value.target.value && !this.form.getFieldError(`${form}`)) {
      let dataobj = {};
      dataobj[form] = changeCurrencytoNumeric(value.target.value);
      this.form.setFieldsValue(dataobj);
    }
  }

  onSelectChange(value) {
    this.selectedRowKeys = value;
  }

  assignSearchProductName(valueSearch): string {
    if (this.poType === "Service" && valueSearch)
      return `name~*${valueSearch}*_AND_type~Service`;
    else if (this.poType === "Unit" && valueSearch)
      return `name~*${valueSearch}*_AND_type~Stockable`;
    else if (this.poType === "Sparepart" && valueSearch)
      return `name~*${valueSearch}*_AND_type~Stockable`;
    else if (this.poType === "Others" && valueSearch)
      return `name~*${valueSearch}*_AND_type~General`;
    else if (this.poType === "Rent to Rent" && valueSearch)
      return `name~*${valueSearch}*_AND_type~Stockable`;
    else if (valueSearch) return `name~*${valueSearch}*`;
    else return "";
  }

  assignSearchProductCode(valueSearch): string {
    if (this.poType === "Service" && valueSearch)
      return `code~*${valueSearch}*_AND_type~Service`;
    else if (this.poType === "Unit" && valueSearch)
      return `code~*${valueSearch}*_AND_type~Stockable`;
    else if (this.poType === "Sparepart" && valueSearch)
      return `code~*${valueSearch}*_AND_type~Stockable`;
    else if (this.poType === "Others" && valueSearch)
      return `code~*${valueSearch}*_AND_type~General`;
    else if (this.poType === "Rent to Rent" && valueSearch)
      return `code~*${valueSearch}*_AND_type~Stockable`;
    else if (valueSearch) return `code~*${valueSearch}*`;
    else return "";
  }

  assignSearchByPoType() {
    if (this.poType === "Service") return `type~Service`;
    else if (this.poType === "Unit") return `type~Stockable`;
    else if (this.poType === "Sparepart") return `type~Stockable`;
    else if (this.poType === "Others") return `type~General`;
    else if (this.poType === "Rent to Rent") return `type~Stockable`;
    else return "";
  }

  getProduct(valueSearch, defaultValue, key?, columnName?) {
    const params: RequestQueryParamsModel = {};
    switch (columnName) {
      case "productName":
        if (valueSearch)
          params.search = this.assignSearchProductName(valueSearch);
        else params.search = this.assignSearchByPoType();
        break;
      case "productCode":
        if (valueSearch)
          params.search = this.assignSearchProductCode(valueSearch);
        else params.search = this.assignSearchByPoType();
        break;
    }
    logisticServices.listProduct(params).then(response => {
      if (defaultValue) this.dataDefaultProduct = response.data;
      else {
        this.dataSource[key].optionCustom[1].option = response.data.map(
          dataMap => {
            return {
              ...dataMap,
              customCode: dataMap.code,
              customName: dataMap.name,
            };
          }
        );
        this.dataSource[key].optionCustom[0].option = response.data.map(
          dataMap => {
            return {
              ...dataMap,
              customCode: dataMap.code,
              customName: dataMap.name,
              name: dataMap.code,
            };
          }
        );
      }
    });
  }

  getBranch(valueSearch) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
    };
    if (valueSearch)
      params.search = `name~*${valueSearch}*_OR_code~*${valueSearch}*_OR_address~*${valueSearch}`;
    this.loading.loadingBranch = true;
    logisticServices
      .listWarehouseBranch(params, "")
      .then(response => {
        this.dataBranch = response.data;
      })
      .finally(() => (this.loading.loadingBranch = false));
  }

  handleChangeBranch(value) {
    this.form.setFieldsValue({ branch: value });
    this.getListWarehouseLocation("");
    this.getUnitCode("");
  }

  handleChangeSupplier(supplier: LabelInValue | undefined): void {
    this.form.resetFields(["supplierBillAddress"]);
    this.dataSupplier.forEach(dataForeach => {
      if (dataForeach.id === supplier?.key) {
        this.dataSupplierBillAddress = dataForeach.addressDataList.filter(
          dataFilter => dataFilter.billTo
        );
        this.setDataForm({
          supplierBillAddress: this.dataSupplierBillAddress.find(
            dataFind => dataFind.primaryBillTo
          )?.address,
        });
      }
    });
  }

  handleChangeCurrency(currencyCode) {
    this.currencyFrom = currencyCode;
  }

  handleChangePurchaseOrderType(value, detailProduct = false) {
    this.poType = value;
    this.getProduct("", true, null, "productName");
    if (!detailProduct) {
      this.dataSource = [];
      this.dataSource = this.dataSource.slice();
    }
    this.form.setFieldsValue({
      totalQty: currencyFormat(0),
      total: currencyFormat(0),
      totalDiscount: currencyFormat(0),
      totalTax: currencyFormat(0),
      grandTotal: currencyFormat(0),
      purchaseOrderType: value,
    });

    const actionAssetId = this.columnsTable.findIndex(
      c => c.dataIndex === "assetId"
    );
    if (
      StringUtils.compare(value, "Service") ||
      StringUtils.compare(value, "Others")
    ) {
      if (actionAssetId === -1) {
        this.columnsTable.splice(5, 0, {
          title: this.$t("lbl_unit_code") as string,
          dataIndex: "assetId",
          key: "assetId",
          width: 200,
          scopedSlots: { customRender: "unitCode" },
        });
      }
    } else {
      if (actionAssetId !== -1) {
        this.columnsTable.splice(actionAssetId, 1);
      }
    }

    this.getUnitCode("");
  }

  getListWarehouseLocation(valueSearch) {
    const branch: LabelInValue | undefined = this.form.getFieldValue("branch");
    if (branch) {
      const params: RequestQueryParamsModel = {
        limit: 10,
        page: 0,
        search: `warehouse.branchWarehouse.secureId~*${branch.key}*_AND_name~*Transit*`,
      };

      if (valueSearch)
        params.search = `warehouse.branchWarehouse.secureId~*${branch.key}*_AND_name~*Transit*_AND_name~*${valueSearch}*`;

      logisticServices.listWarehouseLocation(params, "").then(response => {
        const findColumn = this.columnsTable.find(
          c => c.responsiveColSelect
        )?.responsiveColSelect;
        findColumn[LOCATION_NEEDED_INDEX].options = response.data;
      });
    } else {
      this.$notification.error({
        message: "Error",
        description: "Select branch first to get location",
      });
    }
  }

  getSupplier(valueSearch) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
      search: `supplier~true_AND_active~true`,
    };
    if (valueSearch)
      params.search = `firstName~*${valueSearch}*_OR_lastName~*${valueSearch}*_AND_supplier~true_AND_active~true`;
    this.loading.loadingSupplier = true;
    contactServices
      .listContactData(params)
      .then(res => {
        this.dataSupplier = res.data;
      })
      .finally(() => (this.loading.loadingSupplier = false));
  }

  getSupplierBillAddress(supplierId) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
      search: `supplier~true_AND_secureId~*${supplierId}*_AND_active~true`,
    };

    this.loading.loadingSupplier = true;
    contactServices
      .listContactData(params)
      .then(res => {
        if (res.data.length > 0) {
          res.data.forEach(dataForeach => {
            if (dataForeach.id === supplierId) {
              this.dataSupplierBillAddress = [
                ...this.dataSupplierBillAddress,
                ...dataForeach.addressDataList.filter(
                  dataFilter => dataFilter.billTo
                ),
              ].filter(
                (dataFilter, index, self) =>
                  index ===
                  self.findIndex(t => t.address === dataFilter.address)
              );
            }
          });
        }
      })
      .finally(() => (this.loading.loadingSupplier = false));
  }

  getCurrency(valueSearch) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
    };
    if (valueSearch) params.search = `currencyCode~*${valueSearch}*`;
    this.loading.loadingCurrency = true;
    settingsServices
      .listOfMasterCurrency(params, "")
      .then(response => (this.dataCurrency = response.data))
      .finally(() => (this.loading.loadingCurrency = false));
  }

  getTax(valueSearch) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
      name: `TAX_CALCULATION`,
    };
    if (valueSearch) params.search = `value~*${valueSearch}*`;
    this.loading.loadingTax = true;
    masterServices
      .listMaster(params)
      .then(response => {
        this.dataTax = response.filter(
          dataFilter => dataFilter.value !== "None"
        );
      })
      .finally(() => (this.loading.loadingTax = false));
  }

  getPurchaseOrderType(valueSearch) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
      name: "PURCHASE_ORDER_TYPE",
    };
    if (valueSearch) params.search = `value~*${valueSearch}*`;
    this.loading.loadingPurchaseOrderType = true;
    masterServices
      .listMaster(params)
      .then(response => {
        this.dataPurchaseOrderType = response;
      })
      .finally(() => (this.loading.loadingPurchaseOrderType = false));
  }

  filterDuplicateWithSameId(dataArray) {
    return dataArray.filter(
      (value, index, self) => index === self.findIndex(t => t.id === value.id)
    );
  }

  assignUnitCodeFirstLoad() {
    if (
      this.dataUnitCode &&
      this.dataUnitCode.length > 0 &&
      !this.firstLoad &&
      this.dataDetailPo &&
      Object.keys(this.dataDetailPo).length > 0
    ) {
      const findColumn = this.columnsTable.find(
        c => c.responsiveColSelect
      )?.responsiveColSelect;
      this.dataDetailPo.purchaseOrderLines.forEach(dataMap => {
        if (dataMap.assetId && dataMap.unitCode) {
          findColumn[UNIT_CODE_INDEX].options = [
            ...findColumn[UNIT_CODE_INDEX].options,
            { name: dataMap.unitCode, id: dataMap.assetId },
          ];
        }
      });
      findColumn[UNIT_CODE_INDEX].options = this.filterDuplicateWithSameId(
        findColumn[UNIT_CODE_INDEX].options
      );
      this.firstLoad = true;
    }
  }

  getDetailPurchaseOrder() {
    purchaseServices.getDetailPurchaseOrderNew(this.id).then(response => {
      this.dataDetailPo = response;

      this.getSupplierBillAddress(response.supplierId);
      this.currencyFrom = response.currency;
      this.taxType = response.taxType;
      this.status = response.status as unknown as STATUS;
      this.actualStatusDocument = response.status as unknown as STATUS;
      this.isLuxuryGoods = response.isLuxuryGoods;
      this.transactionDate = moment(response.date);

      this.getTaxBaseVariable();

      if (this.status === STATUS.Draft) this.status = STATUS.Updated;

      if (response.fileAttachment && response.fileAttachment.length > 0) {
        this.fileList = response.fileAttachment
          .filter(dataFilter => dataFilter !== "")
          .map((data, index) => {
            return {
              uid: `${index}`,
              size: 0,
              name: data,
              url: data,
              status: "done",
              type: "",
            };
          });
        this.urlForSend = response.fileAttachment[0];
      }
      this.defaultDescription = response.description;

      const findColumn = this.columnsTable.find(
        c => c.responsiveColSelect
      )?.responsiveColSelect;

      this.dataSource = [];
      response.purchaseOrderLines.forEach((product, i) => {
        const productState = PurchaseOrderMapper.toFormProductState(product);
        productState.key = i;
        productState.no = i + 1;

        if (product.purchaseTaxName && product.purchaseTaxId) {
          findColumn[TAX_RATE_INDEX].options = [
            ...findColumn[TAX_RATE_INDEX].options,
            { name: product.purchaseTaxName, id: product.purchaseTaxId },
          ];
        }
        if (product.productLocation && product.productLocationId) {
          findColumn[LOCATION_NEEDED_INDEX].options = [
            ...findColumn[LOCATION_NEEDED_INDEX].options,
            { name: product.productLocation, id: product.productLocationId },
          ];
        }
        if (product.assetId && product.unitCode) {
          findColumn[UNIT_CODE_INDEX].options = [
            ...findColumn[UNIT_CODE_INDEX].options,
            { name: product.unitCode, id: product.assetId },
          ];
        }

        this.dataSource.push(productState);
      });

      // this column and option for tax
      findColumn[TAX_RATE_INDEX].options = this.filterDuplicateWithSameId(
        findColumn[TAX_RATE_INDEX].options
      );
      // this column and option for location
      findColumn[LOCATION_NEEDED_INDEX].options =
        this.filterDuplicateWithSameId(
          findColumn[LOCATION_NEEDED_INDEX].options
        );
      // this column and option for unit code
      findColumn[UNIT_CODE_INDEX].options = this.filterDuplicateWithSameId(
        findColumn[UNIT_CODE_INDEX].options
      );

      // conditional unit code
      this.handleChangePurchaseOrderType(response.poType, true);

      const formState = PurchaseOrderMapper.toFormState(response);
      formState.products = this.dataSource;
      formState.attachment = this.urlForSend;
      this.setDataForm(formState);
      this.setInclusiveTaxRateColumn();
      this.getListWarehouseLocation("");
    });
  }

  mapColumnsTable(findColumnNumber, findProductCode, findProductName) {
    this.columnsTable = this.columnsTable.map(dataMap => {
      if (dataMap.dataIndex === "no") {
        return findColumnNumber;
      } else if (dataMap.dataIndex === "productCode") {
        return findProductCode;
      } else if (dataMap.dataIndex === "productName") {
        return findProductName;
      } else {
        return dataMap;
      }
    });
    this.columnsTable = this.columnsTable.slice();
  }

  handleResponsiveColumnByWidth(newValue) {
    /**
     * if width <= 800px deleted fixed left or set to false
     * <= 1024px adjust width
     * else like normal
     */
    const findColumnNumber = this.columnsTable.find(
      dataFind => dataFind.dataIndex === "no"
    );
    const findProductCode = this.columnsTable.find(
      dataFind => dataFind.dataIndex === "productCode"
    );
    const findProductName = this.columnsTable.find(
      dataFind => dataFind.dataIndex === "productName"
    );
    const findColumn = this.columnsTable.find(
      c => c.responsiveColSelect
    )?.responsiveColSelect;
    if (newValue <= 800) {
      if (findColumnNumber && findProductCode && findProductName) {
        findColumnNumber.fixed = false;
        findProductCode.fixed = false;
        findProductName.fixed = false;
        this.mapColumnsTable(
          findColumnNumber,
          findProductCode,
          findProductName
        );
      }
    } else if (newValue <= 1300) {
      if (
        findColumnNumber &&
        findProductCode &&
        findProductName &&
        findColumn
      ) {
        findColumnNumber.fixed = "left";
        findProductCode.fixed = "left";
        findProductName.fixed = "left";
        findColumnNumber.width = 100;
        findProductCode.width = 180;
        findProductName.width = 180;
        findColumn[PRODUCT_CODE_INDEX].style = "width: 150px";
        findColumn[PRODUCT_NAME_INDEX].style = "width: 150px";
        findColumnNumber.responsiveColSelect = findColumn;
        this.mapColumnsTable(
          findColumnNumber,
          findProductCode,
          findProductName
        );
      }
    } else {
      if (
        findColumnNumber &&
        findProductCode &&
        findProductName &&
        findColumn
      ) {
        findColumnNumber.fixed = "left";
        findProductCode.fixed = "left";
        findProductName.fixed = "left";
        findColumnNumber.width = 120;
        findProductCode.width = 280;
        findProductName.width = 280;
        findColumn[PRODUCT_CODE_INDEX].style = "width: 250px";
        findColumn[PRODUCT_NAME_INDEX].style = "width: 250px";
        findColumnNumber.responsiveColSelect = findColumn;
        this.mapColumnsTable(
          findColumnNumber,
          findProductCode,
          findProductName
        );
      }
    }
  }

  filterOption(input, option) {
    return (
      option.componentOptions.children[0].componentOptions.children[1].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }

  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "purchaseOrder" });
  }

  created() {
    this.getBranch("");
    this.getRequester("");
    this.getSupplier("");
    this.getCurrency("");
    this.getPurchaseOrderType("");
    this.getTax("");
    this.getProduct("", true);
    this.getTaxRate("");
    this.getInclusiveTaxRate("");
    this.getListOfMerk("");
    this.mode = this.$route.meta.mode;
    if (this.mode === Mode.VIEW) {
      this.id = this.$route.params.id;
    }
  }

  mounted() {
    this.getDefaultInclusiveRate();

    if (this.mode === Mode.VIEW) {
      this.getDetailPurchaseOrder();
    } else {
      this.setDataForm({
        status: STATUS.New,
        tax: TAX_CALCULATION.EXCLUSIVE,
      });
    }
  }

  getDefaultInclusiveRate(): void {
    const { findById } = useTax();

    const purchaseInclusiveTaxRate: IPreferencesResponseDto =
      this.$store.getters["preferenceStore/getPurchaseInclusiveTaxRate"];

    if (!purchaseInclusiveTaxRate || !purchaseInclusiveTaxRate.value) {
      this.defaultPurchaseInclusive = {
        id: "",
        label: "",
        rate: 0,
      };
      return;
    }

    this.defaultPurchaseInclusive.id = purchaseInclusiveTaxRate.value;
    this.defaultPurchaseInclusive.label = purchaseInclusiveTaxRate.name;

    findById(purchaseInclusiveTaxRate.value).then(res => {
      this.defaultPurchaseInclusive.rate = res.rate;
    });
  }

  get formItemLayout() {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
    };
  }
  get isNew(): boolean {
    return this.status === STATUS.New;
  }
  get isDraft(): boolean {
    return this.status === STATUS.Draft;
  }
  get isNeedApproval(): boolean {
    return this.status === STATUS.Need_Approval;
  }
  get isApproved(): boolean {
    return this.status === STATUS.Approved;
  }
  get isRejected(): boolean {
    return this.status === STATUS.Rejected;
  }
  get isCancelled(): boolean {
    return this.status === STATUS.Cancelled;
  }
  get isClosed(): boolean {
    return this.status === STATUS.Closed;
  }
  get isUpdated(): boolean {
    return this.status === STATUS.Updated;
  }

  handleSubmitPo(formState: PurchaseOrderFormState): void {
    this.loading.loadingSubmit = true;
    if (this.id) {
      const payload: PurchaseOrderUpdateDto = PurchaseOrderMapper.toUpdateDto({
        ...formState,
        products: this.dataSource,
        attachment: this.urlForSend,
        deletedLineIds: this.deletedPurchaseOrderLines,
      });
      purchaseServices
        .submitPurchaseOrder(payload, this.id)
        .then(response => {
          this.$notification.success({
            message: "Success",
            description: `documentNumber : ${response.documentNumber}`,
          });
          debounce(() => this.redirectToDetailSubmit(), 1000);
        })
        .catch(() =>
          this.$notification.error({
            message: "Error",
            description: Messages.UPDATE_FAIL,
          })
        )
        .finally(() => (this.loading.loadingSubmit = false));
    } else {
      const payloadCreate: PurchaseOrderCreateDto =
        PurchaseOrderMapper.toCreateDto({
          ...formState,
          products: this.dataSource,
          attachment: this.urlForSend,
        });
      payloadCreate.status = "Need Approval";
      purchaseServices
        .createPurchaseOrder(payloadCreate)
        .then(response => {
          this.$notification.success({
            message: "Success",
            description: `documentNumber : ${response.documentNumber}`,
          });
          this.$router.push(
            `/purchasing/transaction/purchase-order/detail/${response.id}`
          );
        })
        .catch(() =>
          this.$notification.error({
            message: "Error",
            description: Messages.CREATE_FAIL,
          })
        )
        .finally(() => (this.loading.loadingSubmit = false));
    }
  }

  handleUpdatePo(formState: PurchaseOrderFormState): void {
    if (!this.isUpdated) {
      this.status = STATUS.Updated;
    } else {
      const payload: PurchaseOrderUpdateDto = PurchaseOrderMapper.toUpdateDto({
        ...formState,
        products: this.dataSource,
        attachment: this.urlForSend,
        deletedLineIds: this.deletedPurchaseOrderLines,
      });
      this.loading.loadingUpdate = true;
      purchaseServices
        .updatePurchaseOrder(payload, this.id)
        .then(response => {
          this.$router.push("/purchasing/transaction/purchase-order");
          if (response.documentNumber) {
            this.$notification.success({
              message: "Success",
              description: `documentNumber : ${response.documentNumber}`,
            });
          } else {
            this.$notification.success({
              message: "Success",
              description: Messages.UPDATE_SUCCESS,
            });
          }
        })
        .catch(() =>
          this.$notification.error({
            message: "Error",
            description: Messages.CREATE_FAIL,
          })
        )
        .finally(() => (this.loading.loadingUpdate = false));
    }
  }

  handleCreateDraft(formState: PurchaseOrderFormState): void {
    const payloadCreate: PurchaseOrderCreateDto =
      PurchaseOrderMapper.toCreateDto({
        ...formState,
        products: this.dataSource,
        attachment: this.urlForSend,
      });
    payloadCreate.status = "Draft";
    this.loading.loadingDraft = true;
    purchaseServices
      .createPurchaseOrder(payloadCreate)
      .then(response => {
        this.$router.push("/purchasing/transaction/purchase-order");
        if (response.documentNumber)
          this.$notification.success({
            message: "Success",
            description: `documentNumber : ${response.documentNumber}`,
          });
        else
          this.$notification.success({
            message: "Success",
            description: `Success save draft`,
          });
      })
      .catch(() =>
        this.$notification.error({
          message: "Error",
          description: Messages.CREATE_FAIL,
        })
      )
      .finally(() => (this.loading.loadingDraft = false));
  }

  handleReject(): void {
    this.loading.loadingReject = true;
    purchaseServices
      .rejectPurchaseOrder(this.id)
      .then(response => {
        this.$router.push("/purchasing/transaction/purchase-order");
        this.$notification.success({
          message: "Success",
          description: `documentNumber : ${response.documentNumber}`,
        });
      })
      .catch(() =>
        this.$notification.error({
          message: "Error",
          description: `Failed to Rejected`,
        })
      )
      .finally(() => (this.loading.loadingReject = false));
  }

  handleApprove(formState: PurchaseOrderFormState): void {
    const payload: PurchaseOrderUpdateDto = PurchaseOrderMapper.toUpdateDto({
      ...formState,
      products: this.dataSource,
      attachment: this.urlForSend,
      deletedLineIds: this.deletedPurchaseOrderLines,
    });
    this.loading.loadingApprove = true;
    purchaseServices
      .approvePurchaseOrder(payload, this.id)
      .then(response => {
        this.$router.push("/purchasing/transaction/purchase-order");
        this.$notification.success({
          message: "Success",
          description: `documentNumber : ${response.documentNumber}`,
        });
      })
      .catch(() =>
        this.$notification.error({
          message: "Error",
          description: `Failed to Approved`,
        })
      )
      .finally(() => (this.loading.loadingApprove = false));
  }

  handleCancelPo(): void {
    this.loading.loadingCancel = true;
    purchaseServices
      .cancelPurchaseOrder(this.id)
      .then(response => {
        this.$router.push("/purchasing/transaction/purchase-order");
        if (response.documentNumber)
          this.$notification.success({
            message: "Success",
            description: `documentNumber : ${response.documentNumber}`,
          });
        else
          this.$notification.success({
            message: "Success",
            description: "Success Cancel",
          });
      })
      .catch(() =>
        this.$notification.error({
          message: "Error",
          description: `Failed to Cancelled`,
        })
      )
      .finally(() => (this.loading.loadingCancel = false));
  }
}
